import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ChannelSearchBlank() {
  return (
    <div className="px-3 py-4 text-center text-muted">
      <FontAwesomeIcon icon={['far', 'search']} size="2x" />

      <p className="lead mt-3 mb-0">No results found.</p>
    </div>
  );
}

export default ChannelSearchBlank;
