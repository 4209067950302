import { Controller } from 'stimulus';

export default class HorizontalScrollController extends Controller {
  static targets = ['container'];

  back(e) {
    e.preventDefault();

    this.containerTarget.scrollLeft -= this.containerTarget.clientWidth;
  }

  next(e) {
    e.preventDefault();

    this.containerTarget.scrollLeft += this.containerTarget.clientWidth;
  }
}
