import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AdSlot } from 'react-dfp';

import SmallAd from 'components/SmallAd';

export default class AdModule extends Component {
  static propTypes = {
    creativeUrl: PropTypes.string,
    clickThruUri: PropTypes.string,
    dfpSlotId: PropTypes.string,
    type: PropTypes.oneOf(['StaticAd', 'DFPAd'])
  };

  render() {
    const { creativeUrl, clickThruUri, dfpSlotId, type } = this.props;

    return (
      <div>
        {type == 'StaticAd' && creativeUrl && (
          <SmallAd clickThruURI={clickThruUri} sourceURL={creativeUrl} />
        )}

        {type == 'DFPAd' && dfpSlotId && (
          <AdSlot
            adUnit={dfpSlotId}
            dfpNetworkId="9158240"
            fetchNow
            sizeMapping={[
              { viewport: [1200, 0], sizes: [[300, 250]] },
              { viewport: [0, 0], sizes: [[300, 250]] }
            ]}
            sizes={[[300, 250]]}
          />
        )}
      </div>
    );
  }
}
