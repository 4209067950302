import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popper from 'popper.js';
import pluralize from 'lib/utils/pluralize';
import styles from './index.module.scss';

import ChannelSearchItem from '../ChannelSearchItem';
import ChannelSearchBlank from '../ChannelSearchBlank';

export default class ChannelSearchPopover extends Component {
  static propTypes = {
    searchTerm: PropTypes.string,
    parentRef: PropTypes.any.isRequired,
    filteredChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
    togglePopover: PropTypes.func
  };

  static defaultProps = {
    searchTerm: ''
  };

  constructor(...props) {
    super(...props);

    this.domNode = React.createRef();
    this.arrowNode = React.createRef();
    this.popper = null;
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    if (this.domNode.current && this.domNode.current.contains(e.target)) {
      document.removeEventListener('mousedown', this.handleClick, false);
    } else {
      this.props.togglePopover();
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);

    this.popper = new Popper(
      this.props.parentRef.current,
      this.domNode.current,
      {
        placement: 'bottom',
        modifiers: {
          arrow: {
            element: this.arrowNode.current
          }
        }
      }
    );
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    !this.popper.state.isDestroyed && this.popper.destroy();
  }

  render() {
    const { searchTerm, filteredChannels } = this.props;

    return (
      <div
        className={`popover bs-popover-bottom ${styles.popover}`}
        ref={this.domNode}
      >
        <div className="arrow" ref={this.arrowNode} />
        <h3 className="popover-header">
          Searching for &apos;{searchTerm}&apos;
          <br />
          <small>
            Showing {pluralize('result', filteredChannels.length, true)}.
          </small>
        </h3>

        <div className={`popover-body p-0 ${styles['popover-body']}`}>
          {searchTerm.length > 0 && filteredChannels.length > 0 ? (
            filteredChannels.map(channel => (
              <ChannelSearchItem
                key={btoa(JSON.stringify(channel))}
                {...channel}
                togglePopover={this.props.togglePopover}
              />
            ))
          ) : (
            <ChannelSearchBlank />
          )}
        </div>
      </div>
    );
  }
}
