const baseFontSize = () => {
  // Declare a fallback value of 16px.
  // Most browsers use this as the default.
  let size = 16.0;

  try {
    const bodyStyle = window.getComputedStyle(document.body);
    const baseFontSizeFromBody = parseFloat(bodyStyle['font-size']);

    if (baseFontSizeFromBody) size = baseFontSizeFromBody;
  } catch (e) {} //eslint-disable-line no-empty

  return size;
};

export default baseFontSize;
