import Turbolinks from 'turbolinks';
import ReactRailsUJS from 'react_ujs';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import 'bootstrap/dist/js/bootstrap';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import 'froala-editor/js/plugins/align.min.js';
import 'view/styles/application.scss';

Turbolinks.start();
window.Turbolinks = Turbolinks;

window.ReactRailsUJS = ReactRailsUJS;
const componentRequireContext = require.context(
  'view',
  true,
  /^\.\/(?!styles\/).*$/
);
ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.mountComponents();
ReactRailsUJS.detectEvents();

delete window.Turbolinks;

const application = Application.start();
const stimulusContext = require.context('view/controllers', true, /\.js$/);
application.load(definitionsFromContext(stimulusContext));

library.add(fab, fas, far, fal, fad);
dom.watch();
