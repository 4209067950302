import React from 'react';
import PropTypes from 'prop-types';
import HotIcon from './HotIcon';
import ShareIcon from './ShareIcon';
import NewsItemChannel from './NewsItemChannel';
import NewsItemContent from './NewsItemContent';
import NewsItemThumbnail from './NewsItemThumbnail';
import NewsItemTimestamp from './NewsItemTimestamp';
import defaultThumbnail from 'images/logo.png';

function NewsItem({
  id,
  channel,
  content,
  hot,
  sponsored,
  thumbnailUrl,
  updated,
  uri
}) {
  const itemStyle = {};

  let classes = ['news-item'];
  if (sponsored) {
    classes.push('news-item--sponsored');
  }

  return (
    <div className={classes.join(' ')} style={itemStyle}>
      <NewsItemThumbnail linkUri={uri} thumbnailUrl={thumbnailUrl} />

      <div className={'news-item__body'}>
        <h6 className={'news-item__identifier'}>
          {sponsored ? 'Sponsored' : <NewsItemTimestamp updated={updated} />}
        </h6>

        <NewsItemContent content={content} linkUri={uri} />

        <div>
          <div className={'news-item__footer'}>
            {channel && <NewsItemChannel {...channel} />}
            {hot && <HotIcon />}
            {channel && (
              <ShareIcon id={id} slug={channel.slug} content={content} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

NewsItem.propTypes = {
  channel: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  }),
  content: PropTypes.string.isRequired,
  hot: PropTypes.bool,
  primaryChannelId: PropTypes.number,
  sponsored: PropTypes.bool,
  thumbnailUrl: PropTypes.string,
  updated: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]).isRequired,
  uri: PropTypes.string
};

NewsItem.defaultProps = {
  hot: false,
  sponsored: false,
  thumbnailUrl: defaultThumbnail
};

export default NewsItem;
