import { Controller } from 'stimulus';

export default class CommodityPriceController extends Controller {
  static targets = ['container'];

  connect() {
    const tickers = [
      '@GF`## 7',
      '@LE`## 7',
      '@S`## 1,@SM`## 1,@BO`## 1',
      '@C`## 1,@DA`## 1,@W`## 1',
      '@HE`## 1,@O`## 1,QCL`## 1',
      'QNG`## 1'
    ];

    var index = 0;
    const timer = () => {
      if (index + 1 >= tickers.length) {
        index = 0;
      } else {
        index += 1;
      }
      this.futuresWidget.setSymbol(tickers[index]);
    };

    this.futuresWidget = null;
    const self = this;
    window.dtn.futures
      .createFuturesTableWidget({
        apiKey: 'G2YnDkKGwJnB3F9jltRrJKyavGTWn6OE',
        container: '#future-widget',
        symbol: tickers[index],
        visibleFields: ['LAST', 'CHANGE'],
        groupBy: true,
        priceFormat: 'DECIMAL',
        timezone: 'CST'
      })
      .then(function(response) {
        self.futuresWidget = response;
        self.timerHandle = setInterval(timer, 30000);
      });
  }
}
