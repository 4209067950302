import React from 'react';
import PropTypes from 'prop-types';

function NewsItemContent({ content, linkUri }) {
  if (linkUri) {
    return (
      <a
        className={'news-item__content-link'}
        href={linkUri}
        rel="noopener noreferrer"
        target="_blank"
      >
        <h3 className={'news-item__content'}>{content}</h3>
      </a>
    );
  } else {
    return <h3 className={'news-item__content'}>{content}</h3>;
  }
}

NewsItemContent.propTypes = {
  content: PropTypes.string.isRequired,
  linkUri: PropTypes.string
};

export default NewsItemContent;
