import { Controller } from 'stimulus';

export default class RealTimePricesFeederCattle extends Controller {
  connect() {
    const firstElement = document.querySelector(
      '.real-time-prices-page__body .scrollable.nav > a'
    );

    firstElement.click();
    this.select({ currentTarget: firstElement });
  }

  select(event) {
    const id = event.currentTarget.getAttribute('data-id');
    const marketId = `tab-${event.currentTarget.getAttribute(
      'data-container-selector'
    )}`;
    const targetContainer = document.getElementById(marketId);

    if (targetContainer.innerHTML == '') {
      this.addSpinner(targetContainer);
      const url = encodeURI(`/real_time_prices/event_data?domain_id=${id}`);

      const request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.onload = function loadTabData() {
        if (this.status >= 200 && this.status < 400) {
          targetContainer.innerHTML = this.response;
        }
      };

      request.send();
    }
  }

  addSpinner(ele) {
    ele.innerHTML = `<div class="spinner-border position-absolute m-auto" style="top: 0; bottom: 0; left: 0; right: 0;">
                       <span class="sr-only"></span>
                     </div>`;
  }
}
