import { Controller } from 'stimulus';
import fitty from 'fitty';

export default class ChannelHeaderController extends Controller {
  static targets = ['banner', 'title'];

  fittyInstance = null;

  initialize() {
    this.bannerFailed = this.bannerFailed.bind(this);
    this.bannerLoaded = this.bannerLoaded.bind(this);
  }

  connect() {
    this.fittyInstance = fitty(this.titleTarget, {
      minSize: 16,
      maxSize: 40,
      multiLine: false
    });

    if (this.hasBannerTarget) {
      this.bannerTarget.onerror = this.bannerFailed;
      this.bannerTarget.onload = this.bannerLoaded;
    }
  }

  bannerFailed() {
    this.fittyInstance.fit();
  }

  bannerLoaded() {
    this.fittyInstance.fit();
  }
}
