import React from 'react';
import PropTypes from 'prop-types';
import PriceContainer from '../../containers/PriceContainer';
import Price from './Price';
import PriceLoading from './PriceLoading';
import Contract from '../Contract';

function PriceModule({ prices }) {
  const components = {
    IndexPrice: [
      PriceContainer,
      price => ({
        name: price.priceable.id,
        /* eslint-disable react/display-name */
        render: pricePoint => {
          const displayName = price.name || price.priceable.name;

          return pricePoint.size > 0 ? (
            <Price
              amount={pricePoint.get('amount')}
              change={pricePoint.get('change')}
              name={displayName}
            />
          ) : (
            <PriceLoading name={displayName} />
          );
        }
        /* eslint-enable react/display-name */
      })
    ],
    Contract: [
      Contract,
      price => {
        const displayName = price.name || price.priceable.name;

        return {
          ...price.priceable,
          name: displayName
        };
      }
    ]
  };

  return (
    <div className="price-module">
      <div className="price-module__prices">
        {prices.map(price => {
          const type = price.priceable.type;
          const [Component, mapToProps] = components[type];
          const componentProps = mapToProps(price);

          return (
            <Component
              key={`${type}-${btoa(JSON.stringify(price))}`}
              {...componentProps}
            />
          );
        })}
      </div>
    </div>
  );
}

PriceModule.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PriceModule;
