import React from 'react';
import PropTypes from 'prop-types';
import AdModule from '../../components/AdModule';
import CommodityPriceModule from '../../components/CommodityPriceModule';
import CommodityTimelineModule from '../../components/CommodityTimelineModule';
import EmbedModule from '../../components/EmbedModule';
import ImageModule from '../../components/ImageModule';
import PriceModule from '../../components/PriceModule';
import RotatingContentModule from '../../components/RotatingContentModule';
import TextModule from '../../components/TextModule';
import VideoModule from '../../components/VideoModule';

function ContentModule({ id, type, moduleProps }) {
  const modules = {
    AdModule,
    CommodityPriceModule,
    CommodityPriceNearbyModule: CommodityPriceModule,
    CommodityTimelineModule,
    EmbedModule,
    ImageModule,
    PriceModule,
    RotatingContentModule,
    TextModule,
    VideoModule
  };

  const Module = modules[type];

  return <Module id={id} {...moduleProps} />;
}

ContentModule.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  moduleProps: PropTypes.object.isRequired
};

export default ContentModule;
