import ActionCable from 'actioncable';

let AuthenticatedCable = ActionCable;
AuthenticatedCable.createConsumer = function(url, token = null) {
  url =
    url == null
      ? this.getConfig('url') || this.INTERNAL.default_mount_path
      : url;

  return new AuthenticatedCable.Consumer(this.createWebSocketURL(url), token);
};

AuthenticatedCable.Consumer = class Consumer extends ActionCable.Consumer {
  constructor(url, token = null) {
    super(url);

    this.url = url;
    this.token = token;

    this.subscriptions = new AuthenticatedCable.Subscriptions(this);
    this.connection = new AuthenticatedCable.Connection(this);
  }
};

AuthenticatedCable.Connection = class Connection extends ActionCable.Connection {
  constructor(consumer) {
    super(consumer);
  }

  open() {
    const protocols = ActionCable.INTERNAL.protocols;

    if (this.isActive()) {
      ActionCable.log(
        'Attempted to open WebSocket, but existing socket is ' + this.getState()
      );
      throw new Error('Existing connection must be closed before opening');
    } else {
      ActionCable.log(
        'Opening WebSocket, current state is ' +
          this.getState() +
          ', subprotocols: ' +
          protocols
      );

      if (this.webSocket != null) {
        this.uninstallEventHandlers();
      }

      const token = this.consumer.token;
      let _protocols = protocols;

      if (token != null || token != undefined) {
        _protocols = _protocols.concat(`authenticated-cable-token-${token}`);
      }
      else {
        _protocols = _protocols.concat('authenticated-cable-open');
      }

      this.webSocket = new WebSocket(this.consumer.url, _protocols);
      this.installEventHandlers();
      this.monitor.start();

      return true;
    }
  }

  _events = {
    ...this.events,
    message: function(event) {
      if (!this.isProtocolSupported()) return;

      const { identifier, message, type } = JSON.parse(event.data);
      const message_types = {
        ...ActionCable.INTERNAL.message_types,
        command: 'command'
      };

      switch (type) {
      case message_types.welcome:
        this.monitor.recordConnect();
        this.subscriptions.reload();
        break;
      case message_types.ping:
        this.monitor.recordPing();
        break;
      case message_types.confirmation:
        this.subscriptions.notify(identifier, 'connected');
        break;
      case message_types.rejection:
        this.subscriptions.reject(identifier);
        break;
      case message_types.command:
        // { type: 'command', message: { command: 'name of command goes here', data: ['args', 'go', 'here', 'as', 'array'] } }
        this.subscriptions.command(identifier, message);
        break;
      default:
        this.subscriptions.notify(identifier, 'received', message);
        break;
      }
    }
  };

  events = this._events;
};

AuthenticatedCable.Subscriptions = class Subscriptions extends ActionCable.Subscriptions {
  constructor(consumer) {
    super(consumer);
  }

  command(subscription, message) {
    this.notify(subscription, 'command', message);
  }
};

export default AuthenticatedCable;
