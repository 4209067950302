import React from 'react';
import PropTypes from 'prop-types';

function ImageModule({ url }) {
  return (
    <div className="image-module">
      <img alt="" className="w-100" src={url} />
    </div>
  );
}

ImageModule.propTypes = {
  url: PropTypes.string.isRequired
};

export default ImageModule;
