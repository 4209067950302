import { Controller } from 'stimulus';

export default class CommodityTimelineController extends Controller {
  static targets = ['container'];

  connect() {
    this.containerId = `timeline-module-${Math.floor(
      Math.random() * Date.now()
    ).toString(36)}`;

    this.containerTarget.setAttribute('id', this.containerId);
    const commodity = this.element.dataset['commodity'];
    const limit = this.parseInt(this.element.dataset['contractLimit']);

    window.dtn.futures.createFuturesTableWidget({
      apiKey: 'G2YnDkKGwJnB3F9jltRrJKyavGTWn6OE',
      container: `#${this.containerId}`,
      symbol: `${commodity}\`## ${limit}`,
      visibleFields: ['LAST', 'CHANGE'],
      groupBy: true,
      priceFormat: 'DECIMAL',
      timezone: 'CST'
    });
  }

  parseInt(value) {
    value = parseInt(value);

    if (isNaN(value) || value > 6) {
      return 6;
    } else {
      return value;
    }
  }
}
