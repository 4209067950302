import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FeedNotice({ backgroundColor, color, icon, onClick, text, title }) {
  const computedStyles = {
    backgroundColor,
    color,
    cursor: onClick == null ? 'auto' : 'pointer'
  };

  return (
    <div
      className={styles['feed-notice']}
      onClick={onClick}
      style={computedStyles}
    >
      <div className="align-items-center d-flex justify-content-center mb-2">
        {icon && <FontAwesomeIcon icon={icon} fixedWidth />}

        {title && <h6 className={styles.heading}>{title}</h6>}

        {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
      </div>

      <p className={styles.text}>{text}</p>
    </div>
  );
}

FeedNotice.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  title: PropTypes.string
};

FeedNotice.defaultProps = {
  backgroundColor: 'lightgray',
  color: 'black'
};

export default FeedNotice;
