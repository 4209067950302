import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SmallAd extends Component {
  static propTypes = {
    clickThruURI: PropTypes.string,
    sourceURL: PropTypes.string.isRequired
  };

  static defaultProps = {
    clickThruURI: null
  };

  render() {
    const { clickThruURI, sourceURL } = this.props;

    return (
      <div className="w-100">
        {clickThruURI == null ? (
          <img
            className="d-block img-fluid m-auto w-100"
            src={sourceURL}
            alt="ad"
          />
        ) : (
          <a
            className="d-block"
            href={clickThruURI}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="d-block img-fluid w-100" src={sourceURL} alt="ad" />
          </a>
        )}
      </div>
    );
  }
}
