/* eslint-env node, browser */

import AppContext from './context';

/**
 * Get the user agent from the global context or use the provided default.
 *
 * @param {string} [defaultUserAgent] The fallback user agent if one is not
 * detected. Defaults to `null`.
 * @returns {string} The detected user agent or the fallback.
 */
function getUserAgentOrUseDefault(defaultUserAgent = null) {
  const globalObject = window || global;
  const navigator = globalObject.navigator || {};

  return navigator.userAgent || defaultUserAgent;
}

function createClickThru({
  clickableType,
  clickableId,
  userAgent = null,
  referer = null
}) {
  const context = new AppContext({ environment: process.env.NODE_ENV });

  const finalUserAgent =
    userAgent == null ? getUserAgentOrUseDefault() : userAgent;

  return fetch(context.endpoint('clickThrus.collection'), {
    body: JSON.stringify({
      click_thru: {
        clickable_type: clickableType,
        clickable_id: clickableId,
        user_agent: finalUserAgent,
        referer: referer
      }
    }),
    headers: {
      Accept: 'application/vnd.api+json;charset=UTF-8',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'POST'
  }).then(response => {
    if (response.ok) {
      return Promise.resolve();
    }

    const error = new Error('Unable to communicate with server!');
    error.name = 'CommunicationError';

    throw error;
  });
}

export default createClickThru;
