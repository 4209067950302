import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ChangeDirection({ className, direction }) {
  const icon = direction
    ? ['fas', `arrow-alt-${direction}`]
    : ['fad', 'minus'];

  return (
    <FontAwesomeIcon
      className={className}
      fixedWidth
      icon={icon}
    />
  );
}

ChangeDirection.propTypes = {
  className: PropTypes.any,
  direction: PropTypes.oneOf(['up', 'down'])
};

ChangeDirection.defaultProps = {
  direction: null
};

export default ChangeDirection;
