import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthenticatedCableContext from '../../../src/containers/AuthenticatedCableContext';
import BottomScrollListener from 'react-bottom-scroll-listener';
import NewsFeed from '../../components/NewsFeed';
import NewsFeedLoading from '../../components/NewsFeedLoading';
import NewsFeedEmpty from '../../components/NewsFeedEmpty';
import { fromJS, List } from 'immutable';
import { normalize } from '../../models/news_item';
import compareDesc from 'date-fns/compare_desc';
import remToPx from '../../../src/lib/utils/remToPx';

export default class NewsFeedContainer extends Component {
  static propTypes = {
    channelIds: PropTypes.arrayOf(PropTypes.number),
    exclusive: PropTypes.bool
  };

  static defaultProps = {
    exclusive: false
  }

  cableRef = React.createRef();
  listRef = React.createRef();

  constructor() {
    super();

    this.state = {
      newsItems: List(),
      newsItemsLoaded: false,
      pendingNewsItems: List(),
      updateAvailable: false
    };

    this.handleAcceptUpdate = this.handleAcceptUpdate.bind(this);
    this.handleNewsItemsReceived = this.handleNewsItemsReceived.bind(this);
    this.handleScrollToBottom = this.handleScrollToBottom.bind(this);
  }

  handleAcceptUpdate() {
    this.setState(
      state => ({
        ...state,
        newsItems: state.newsItems
          .concat(state.pendingNewsItems)
          .sort(byUpdatedDesc),
        pendingNewsItems: List(),
        updateAvailable: false
      }),
      () =>
        this.listRef.current &&
        this.listRef.current.parentNode.scrollTo({ top: 0 })
    );
  }

  handleNewsItemsReceived({ command, data }) {
    if (command == 'load') {
      this.setState(state => ({
        ...state,
        newsItems: fromJS(normalize(data)).sort(byUpdatedDesc),
        newsItemsLoaded: true
      }));
    } else if (command == 'load_page') {
      this.setState(state => ({
        ...state,
        newsItems: state.newsItems
          .concat(fromJS(normalize(data)))
          .filter(findUnique)
          .sort(byUpdatedDesc)
      }));
    } else if (command == 'insert') {
      this.setState(state => ({
        ...state,
        pendingNewsItems: state.pendingNewsItems
          .concat(fromJS(normalize(data)))
          .filter(findUnique)
          .sort(byUpdatedDesc),
        updateAvailable: true
      }));
    } else if (command == 'replace') {
      this.setState(state => ({
        ...state,
        newsItems: this.state.newsItems
          .map(function(newsItem) {
            return newsItem.get('id') == data.id
              ? fromJS(normalize([data])).first()
              : newsItem;
          })
          .filter(findUnique)
          .sort(byUpdatedDesc)
      }));
    }
  }

  handleScrollToBottom() {
    this.cableRef.current &&
      this.cableRef.current.subscription.perform('page', {
        direction: 'prev'
      });
  }

  render() {
    const { channelIds, exclusive } = this.props;

    return (
      <AuthenticatedCableContext.Provider>
        <AuthenticatedCableContext.Consumer
          channel={{
            channel: 'NationalBeefWire::Desktop::NewsItemChannel',
            channelIds,
            exclusive
          }}
          onReceived={this.handleNewsItemsReceived}
          ref={this.cableRef}
        >
          {this.state.newsItemsLoaded ? (
            this.state.newsItems.size == 0 ? (
              <NewsFeedEmpty />
            ) : (
              <BottomScrollListener
                debounce={175}
                offset={remToPx(30)}
                onBottom={this.handleScrollToBottom}
              >
                {scrollRef => (
                  <NewsFeed
                    listRef={this.listRef}
                    newsItems={this.state.newsItems}
                    onAcceptUpdate={this.handleAcceptUpdate}
                    updateAvailable={this.state.updateAvailable}
                    viewportRef={scrollRef}
                  />
                )}
              </BottomScrollListener>
            )
          ) : (
            <NewsFeedLoading />
          )}
        </AuthenticatedCableContext.Consumer>
      </AuthenticatedCableContext.Provider>
    );
  }
}

function findUnique(newsItem, index, collection) {
  return (
    collection.findLastIndex(item => newsItem.get('id') == item.get('id')) ==
    index
  );
}

function byUpdatedDesc(a, b) {
  return compareDesc(a.get('updated'), b.get('updated'));
}
