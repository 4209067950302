import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NewsFeedEmpty() {
  return (
    <div className="news-feed--empty">
      <FontAwesomeIcon icon={['fad', 'newspaper']} size="3x" />

      <h4 className="news-feed__empty-title">No news yet</h4>

      <p className="news-feed__empty-message">
        We don&apos;t have any news for this channel yet. Keep checking back!
      </p>
    </div>
  );
}
