import createChannelSubscription from './createChannelSubscription';
import createClickThru from './createClickThru';
import deleteChannelSubscription from './deleteChannelSubscription';
import getNewsItem from './getNewsItem';
import listChannelSubscriptions from './listChannelSubscriptions';

const appClient = {
  createChannelSubscription,
  createClickThru,
  deleteChannelSubscription,
  getNewsItem,
  listChannelSubscriptions
};

export default appClient;
