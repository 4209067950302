import remToPx from './remToPx';

const isVisible = (
  element,
  viewport,
  allowedVarianceDeltaInRems = 0,
  direction
) => {
  const allowedVarianceDeltaInPixels = remToPx(allowedVarianceDeltaInRems);
  const viewportBounds = viewport.getBoundingClientRect();
  const elementBounds = element.getBoundingClientRect();
  const paddings =
    parseInt(getComputedStyle(element).paddingLeft) +
    parseInt(getComputedStyle(element).paddingRight);

  if (direction === 'vertical') {
    const allowedBounds = {
      top: viewportBounds.top - allowedVarianceDeltaInPixels,
      bottom: viewportBounds.bottom + allowedVarianceDeltaInPixels
    };

    return (
      (elementBounds.top >= allowedBounds.top &&
        elementBounds.bottom > allowedBounds.top) &&
      (elementBounds.bottom <= allowedBounds.bottom &&
        elementBounds.top < allowedBounds.bottom)
    );
  }
  return (
    elementBounds.top + allowedVarianceDeltaInPixels >=
      viewportBounds.top - allowedVarianceDeltaInPixels &&
    elementBounds.left + paddings + allowedVarianceDeltaInPixels >=
      viewportBounds.left - allowedVarianceDeltaInPixels &&
    elementBounds.right - paddings - allowedVarianceDeltaInPixels <=
      viewportBounds.right + allowedVarianceDeltaInPixels &&
    elementBounds.bottom - allowedVarianceDeltaInPixels <=
      viewportBounds.bottom + allowedVarianceDeltaInPixels
  );
};

export default isVisible;
