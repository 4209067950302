import React from 'react';
import PropTypes from 'prop-types';
import { AdSlot } from 'react-dfp';

function DFPAd({ networkId, slotId, height, width }) {
  return (
    <AdSlot
      adUnit={slotId}
      dfpNetworkId={networkId}
      fetchNow
      sizeMapping={[
        { viewport: [1200, 0], sizes: [[364, 303], [width, height]] },
        { viewport: [0, 0], sizes: [[width, height]] }
      ]}
      sizes={[[364, 303], [width, height]]}
    />
  );
}

DFPAd.propTypes = {
  networkId: PropTypes.string.isRequired,
  slotId: PropTypes.string.isRequired
};

export default DFPAd;
