import React from 'react';
import PropTypes from 'prop-types';

function VideoModule({ url }) {
  return (
    <video autoPlay className="video-module" controls muted>
      <source src={url} type="video/mp4" />
      Your browser does not support this video format.
    </video>
  );
}

VideoModule.propTypes = {
  url: PropTypes.string.isRequired
};

export default VideoModule;
