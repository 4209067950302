/* eslint-env node, browser */

import AppContext from './context';

function getNewsItem(id) {
  const context = new AppContext({ environment: process.env.NODE_ENV });

  return fetch(context.endpoint('newsItems.member', { id }), {
    headers: {
      Accept: 'application/vnd.api+json;charset=UTF-8',
      'Content-Type': 'application/vnd.api+json;charset=UTF-8'
    },
    method: 'GET'
  }).then(response => {
    if (response.ok) {
      return response.json();
    }

    const error = new Error('Unable to communicate with server!');
    error.name = 'CommunicationError';

    throw error;
  });
}

export default getNewsItem;
