import baseFontSize from './baseFontSize';

const remToPx = function(rems) {
  try {
    return parseFloat(rems) * baseFontSize() || 0;
  } catch (e) {
    return 0;
  }
};

export default remToPx;
