import get from 'lodash/get';
import template from 'lodash/template';

/**
 * @constant {Object} appConfig
 * @default
 */
const appConfig = {
  default: {
    endpoints: {
      channelSubscriptions: {
        collection: '/api/channel_subscriptions',
        member: '/api/channel_subscriptions/:id'
      },
      clickThrus: {
        collection: '/api/click_thrus'
      },
      newsItems: {
        member: '/api/news_items/:id'
      }
    }
  },
  production: {
    protocol: null,
    host: null,
    cookieDomain: '.nationalbeefwire.com'
  },
  development: {
    protocol: 'http',
    host: 'localhost:5000',
    cookieDomain: 'localhost'
  }
};

/**
 * Returns the application configuration for the given environment.
 *
 * @param {Object} options Object with options.
 * @param {string} options.environment Environment name.
 * @returns {Object} Application configuration for specifed environment.
 */
function getAppConfig({ environment }) {
  let config = { ...appConfig.default };

  if (environment && appConfig.hasOwnProperty(environment)) {
    config = {
      ...config,
      ...appConfig[environment]
    };
  }

  return config;
}

/**
 * Builds an application context object for the specified environment.
 *
 * @exports
 * @class AppContext
 * @classdesc Provides access to retrieve application context for a specified
 * environment.
 */
class AppContext {
  constructor({ environment }) {
    this.config = getAppConfig({ environment });
  }

  /**
   * Returns the domain used for scoping cookies.
   *
   * @readonly
   * @returns {string} The cookie domain.
   * @memberof AppContext
   */
  get cookieDomain() {
    return this.config.cookieDomain;
  }

  /**
   * Returns the endpoint for the specified application action.
   *
   * @param {string} action Application action.
   * @returns {string} Fully-qualified endpoint URI constructed from the
   * specified endpoint action, protocol, and host.
   * @memberof AppContext
   */
  endpoint(action, options = {}) {
    const endpointTemplate = template(get(this.config.endpoints, action), {
      interpolate: new RegExp(':([\\w-]+)', 'g')
    });

    const protocolAndDomain =
      this.config.protocol && this.config.host
        ? `${this.config.protocol}://${this.config.host}`
        : '';

    return `${protocolAndDomain}${endpointTemplate(options)}`;
  }
}

export default AppContext;
