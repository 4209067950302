/**
 * Computes a unique hash based on an object's properties.
 *
 * @export
 * @param {*} object
 * @returns String
 */
export default function objectHash(object) {
  return btoa(encodeURIComponent(JSON.stringify(object)));
}
