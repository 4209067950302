import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeedNotice from '../FeedNotice';
import NewsItem from '../NewsItem';
import { List } from 'immutable';
import objectHash from '../../utils/objectHash';

export default class NewsFeed extends Component {
  static propTypes = {
    listRef: PropTypes.any,
    newsItems: PropTypes.instanceOf(List),
    onAcceptUpdate: PropTypes.func,
    updateAvailable: PropTypes.bool,
    viewportRef: PropTypes.any
  };

  static defaultProps = {
    newsItems: new List(),
    onAcceptUpdate: () => null,
    updateAvailable: false
  };

  render() {
    const {
      listRef,
      newsItems,
      onAcceptUpdate,
      updateAvailable,
      viewportRef
    } = this.props;

    return (
      <>
        {updateAvailable && (
          <FeedNotice
            backgroundColor="#7157d9"
            color="white"
            icon="sync-alt"
            onClick={onAcceptUpdate}
            text="Updates are available for the news feed. Click here to see the latest updates."
            title="Update"
          />
        )}
        <div
          className="news-feed__viewport"
          ref={viewportRef}
          data-controller="share"
        >
          <div className="news-feed__items" ref={listRef}>
            {newsItems
              .map(item => (
                <NewsItem key={objectHash(item.toJS())} {...item.toJS()} />
              ))
              .toJS()}
          </div>

          <div
            className="hidden modal fade news-feed__share-modal"
            id="share-modal"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Share News Item</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-left">
                    <a
                      className="sharing-icon"
                      data-target="share.twitterLink"
                      target="_blank"
                    >
                      <i className="fab fa-twitter-square align-middle fa-4x mx-2"></i> Twitter
                    </a>
                    <hr />
                    <a
                      className="sharing-icon"
                      data-target="share.facebookLink"
                      data-action="click->share#facebookPopup"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square align-middle fa-4x mx-2"></i> Facebook
                    </a>
                    <hr />
                    <a
                      className="sharing-icon"
                      data-target="share.emailLink"
                      target="_blank"
                    >
                      <i className="fa fa-envelope-square align-middle fa-4x mx-2"></i> Email
                    </a>
                    <hr />
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#share.link, #link-share-modal"
                      data-dismiss="modal"
                    >
                      <span className="sharing-icon fa-stack align-middle">
                        <i className="fas fa-square fa-stack-2x"></i>
                        <i className="fas fa-link fa-stack-1x fa-inverse"></i>
                      </span>
                      Link
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden modal" id="link-share-modal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    data-target="video-catalogs--desktop.modalLotLabel video-catalogs--mobile.modalLotLabel"
                  ></h5>
                  <button type="button" className="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  <h6>Use the link below to share:</h6>
                  <p className="my-3">
                    <input
                      type="text"
                      className="form-control"
                      readOnly="readonly"
                      data-target="share.urlCopyInput"
                    />
                  </p>
                </div>
                <div className="modal-footer">
                  <span
                    className="text-success font-weight-bold clipboard-msg"
                    data-target="share.lotCopiedMsg"
                    style={{
                      visibility: 'hidden',
                      opacity: 0,
                      transition: 'opacity 0.2s',
                      transitionTimingFunction: 'ease'
                    }}
                  >
                    Copied to clipboard
                  </span>
                  <button
                    className="btn btn-primary"
                    data-action="click->share#copyUrl"
                  >
                    Copy Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
