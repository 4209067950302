import boxModelCorrection from './boxModelCorrection';
import pxToRem from './pxToRem';

const scrollPosition = (element, container, axis) => {
  const elementBounds = element.getBoundingClientRect();
  const containerBounds = container.getBoundingClientRect();
  const distanceInPixels =
    axis === 'y'
      ? elementBounds.y - containerBounds.y
      : elementBounds.x - containerBounds.x - boxModelCorrection(container);

  return pxToRem(distanceInPixels);
};

export default scrollPosition;
