import baseFontSize from './baseFontSize';

const pxToRem = pixels => {
  try {
    const calculatedValue = parseFloat(pixels) / baseFontSize();

    if (calculatedValue === Infinity || isNaN(calculatedValue)) {
      return 0;
    }

    return calculatedValue;
  } catch (e) {
    return 0;
  }
};

export default pxToRem;
