import React from 'react';
import PropTypes from 'prop-types';

function NewsItemThumbnail({ linkUri, thumbnailUrl }) {
  if (linkUri) {
    return (
      <a className={'news-item__thumbnail-wrapper'} href={linkUri}>
        <img className={'news-item__thumbnail-image'} src={thumbnailUrl} />
      </a>
    );
  } else {
    return (
      <span className={'news-item__thumbnail-wrapper'}>
        <img className={'news-item__thumbnail-image'} src={thumbnailUrl} />
      </span>
    );
  }
}

NewsItemThumbnail.propTypes = {
  linkUri: PropTypes.string,
  thumbnailUrl: PropTypes.string.isRequired
};

export default NewsItemThumbnail;
