import React from 'react';
import PropTypes from 'prop-types';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';

function NewsItemTimestamp({ updated }) {
  return (
    <>
      <span>Posted </span>

      <span className={'news-item__timestamp'}>
        {distanceInWordsToNow(updated, { addSuffix: true })}
      </span>
    </>
  );
}

NewsItemTimestamp.propTypes = {
  updated: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ])
};

export default NewsItemTimestamp;
