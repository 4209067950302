import { Controller } from 'stimulus';

export default class RealTimePrices extends Controller {
  static targets = [
    'steerContainer',
    'calvesContainer',
    'stockersContainer',
    'heavyFeedersContainer',
    'steerTrackerContainer',
    'top25Container',
    'heifersContainer',
    'hot100Container',
    'big50Container'
  ];

  static values = { url: String };

  initialize() {
    this.allowedCategories = [
      'heifers',
      'calves',
      'stockers',
      'heavyFeeders',
      'steerTracker',
      'top25',
      'hot100',
      'big50',
      'steers'
    ];
  }

  connect() {
    let category = this.element.dataset.defaultValue;
    if (!this.allowedCategories.includes(category)) {
      category = 'steers';
    }
    const defaultSelector = document.querySelector(
      `[data-category="${category}"]`
    );

    this.preselect(defaultSelector);
  }

  preselect(defaultSelector) {
    defaultSelector.click();
    this.select({ currentTarget: defaultSelector });
  }

  select(event) {
    const category = event.currentTarget.getAttribute('data-category');
    const targetContainer = this.targets.find(`${category}Container`);

    if (targetContainer.innerHTML == '') {
      this.addSpinner(targetContainer);
      const url = encodeURI(
        `/real_time_prices/category_data?category=${category}`
      );

      const request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.onload = function loadTabData() {
        if (this.status >= 200 && this.status < 400) {
          targetContainer.innerHTML = this.response;
        }
      };

      request.send();
    }
  }

  addSpinner(ele) {
    ele.innerHTML = `<div class="spinner-border position-absolute m-auto" style="top: 0; bottom: 0; left: 0; right: 0;">
                       <span class="sr-only"></span>
                     </div>`;
  }
}
