import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentModule from '../ContentModule';

export default class RotatingContentModule extends Component {
  static propTypes = {
    contentModules: PropTypes.arrayOf(PropTypes.object)
  };

  constructor(...props) {
    super(...props);

    this.state = {
      cursor: 0
    };

    this.rotate = this.rotate.bind(this);
  }

  findNextCursor(currentCursor, totalContentModules) {
    let probableCursor = currentCursor + 1;

    if (probableCursor >= totalContentModules) {
      probableCursor = 0;
    }

    return probableCursor;
  }

  rotate() {
    this.setState((state, props) => ({
      cursor: this.findNextCursor(state.cursor, props.contentModules.length)
    }));
  }

  componentDidMount() {
    this.timerHandle = setInterval(this.rotate, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.timerHandle);
  }

  render() {
    const { cursor } = this.state;
    const { contentModules } = this.props;

    const contentModule = contentModules[cursor];

    return (
      <ContentModule
        key={btoa(JSON.stringify(contentModule))}
        id={contentModule.id}
        type={contentModule.type}
        moduleProps={{ ...contentModule }}
      />
    );
  }
}
