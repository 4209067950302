import { boolean, date, mixed, number, object, string } from 'yup';
import camelCase from 'lodash/fp/camelCase';
import defaultThumbnail from '../images/default_thumbnail.png';

const attributes = {
  channel: object()
    .when({
      is: channel =>
        channel == null ||
        (typeof channel == 'object' &&
          (channel.name === undefined || channel.slug === undefined)),
      then: object().strip(),
      otherwise: object().shape({
        name: string().required(),
        slug: string().required()
      })
    })
    .notRequired()
    .noUnknown(),
  content: string().required(),
  id: number().required(),
  hot: boolean().default(false),
  sponsored: boolean().default(false),
  thumbnailUrl: string()
    .transform(value => (value === null ? undefined : value))
    .default(defaultThumbnail)
    .url()
    .required(),
  updated: date().required(),
  uri: string()
    .transform(value => {
      return mixed().isType(value) && value !== null && value.trim() == ''
        ? null
        : value;
    })
    .url()
    .nullable()
    .notRequired()
};

const schema = object()
  .from('primaryChannel', 'channel')
  .from('thumbnailUri', 'thumbnailUrl')
  .from('title', 'content')
  .shape(attributes)
  .noUnknown();

function formatKeys(object) {
  return Object.entries(object).reduce(
    (obj, [k, v]) => ({
      ...obj,
      [camelCase(k)]: v instanceof Object ? formatKeys(v) : v
    }),
    {}
  );
}

function fromJSONAPI(payload) {
  if (payload.hasOwnProperty('jsonapi')) {
    let flattened = {
      ...mergeData(payload.data)
    };

    if (
      payload.data.hasOwnProperty('relationships') &&
      Object.keys(payload.data.relationships).length > 0
    ) {
      flattened = {
        ...flattened,
        ...mergeRelationships(payload.data.relationships, payload.included)
      };
    }

    return flattened;
  }

  return payload.hasOwnProperty('data') ? payload.data : payload;
}

function mergeData(data) {
  return {
    id: data.id,
    ...data.attributes
  };
}

function mergeRelationship(relationship, included) {
  const foundIncludedRelationship = included.find(
    obj => obj.id == relationship.id && obj.type == relationship.type
  );

  return mergeData(foundIncludedRelationship);
}

function mergeRelationships(relationships, included) {
  return Object.entries(relationships).reduce(
    (obj, [k, { data: v }]) => ({
      ...obj,
      [k]: Array.isArray(v)
        ? v.map(rel => mergeRelationship(rel, included))
        : mergeRelationship(v, included)
    }),
    {}
  );
}

function normalize(object) {
  if (Array.isArray(object)) {
    return object.map(normalizeSingle);
  }

  return normalizeSingle(object);
}

function normalizeSingle(object) {
  return schema.cast(formatKeys(fromJSONAPI(object)));
}

export { attributes, schema, normalize };

export default schema;
