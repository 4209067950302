import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';

function CommodityPriceModule({
  name,
  price,
  change,
  high,
  low,
  open,
  monthHigh,
  monthLow
}) {
  const changeAsFloat = parseFloat(change);
  const direction =
    !Number.isNaN(changeAsFloat) && changeAsFloat > 0.0 ? 'up' : 'down';

  return (
    <div className="commodity-price-module">
      <h1 className="commodity-price-module__title">{name} Prices</h1>

      <div className="commodity-price-module__prices">
        <div className="commodity-price-module__current">
          <span className="commodity-price-module__label">Current</span>
          <span className="commodity-price-module__amount">
            {numeral(price).format('$0,0.00')}

            {direction ? (
              <FontAwesomeIcon
                icon={['fas', `arrow-alt-${direction}`]}
                style={{
                  fontSize: '.75em',
                  marginLeft: '.25rem',
                  verticalAlign: 'baseline'
                }}
                fixedWidth
              />
            ) : null}
          </span>
        </div>

        <div className="commodity-price-module__detail">
          <span className="commodity-price-module__label">Change</span>
          <span className="commodity-price-module__amount">
            {numeral(change).format('$0,0.00')}
          </span>
        </div>

        <div className="commodity-price-module__detail">
          <span className="commodity-price-module__label">High</span>
          <span className="commodity-price-module__amount">
            {numeral(high).format('$0,0.00')}
          </span>
        </div>

        <div className="commodity-price-module__detail">
          <span className="commodity-price-module__label">Low</span>
          <span className="commodity-price-module__amount">
            {numeral(low).format('$0,0.00')}
          </span>
        </div>

        <div className="commodity-price-module__detail">
          <span className="commodity-price-module__label">Open</span>
          <span className="commodity-price-module__amount">
            {numeral(open).format('$0,0.00')}
          </span>
        </div>

        <div className="commodity-price-module__detail">
          <span className="commodity-price-module__label">Month High</span>
          <span className="commodity-price-module__amount">
            {numeral(monthHigh).format('$0,0.00')}
          </span>
        </div>

        <div className="commodity-price-module__detail">
          <span className="commodity-price-module__label">Month Low</span>
          <span className="commodity-price-module__amount">
            {numeral(monthLow).format('$0,0.00')}
          </span>
        </div>
      </div>
    </div>
  );
}

CommodityPriceModule.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  change: PropTypes.string.isRequired,
  high: PropTypes.string.isRequired,
  low: PropTypes.string.isRequired,
  open: PropTypes.string.isRequired,
  monthHigh: PropTypes.string.isRequired,
  monthLow: PropTypes.string.isRequired
};

export default CommodityPriceModule;
