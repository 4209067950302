import { Controller } from 'stimulus';

export default class ShareController extends Controller {
  static targets = [
    'twitterLink',
    'facebookLink',
    'emailLink',
    'link',
    'urlCopyInput',
    'lotCopiedMsg'
  ];

  updateLinks(event) {
    const newsItemUrl = `${location.protocol}//${
      location.host
    }/channels/${event.currentTarget.getAttribute(
      'data-news-item-slug'
    )}?news_item_id=${event.currentTarget.getAttribute('data-news-item-id')}`;
    const newsItemContent = event.currentTarget.getAttribute(
      'data-news-item-content'
    );

    this.twitterLinkTarget.href = `https://www.twitter.com/intent/tweet?text=${encodeURIComponent(
      newsItemContent
    )}&url=${encodeURIComponent(newsItemUrl)}`;

    this.facebookLinkTarget.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      newsItemUrl
    )}`;

    this.emailLinkTarget.href = `mailto:?subject=${newsItemContent}&body=${newsItemUrl}`;
    this.urlCopyInputTarget.value = newsItemUrl;
  }

  copyUrl() {
    this.urlCopyInputTarget.select();
    this.urlCopyInputTarget.setSelectionRange(0, 99999);
    document.execCommand('copy');

    this.lotCopiedMsgTarget.style.visibility = 'visible';
    this.lotCopiedMsgTarget.style.opacity = 1;
    setTimeout(() => {
      this.lotCopiedMsgTarget.style.opacity = 0;
      setTimeout(() => {
        this.lotCopiedMsgTarget.style.visibility = 'hidden';
      }, 500);
    }, 5000);
  }

  facebookPopup(e) {
    e.preventDefault();

    window.open(
      e.currentTarget.href,
      'fbShareWindow',
      'height=450, width=550 toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
    );
  }
}
