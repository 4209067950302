import { Controller } from 'stimulus';

export default class CommodityPriceController extends Controller {
  static targets = ['container'];

  connect() {
    this.containerId = `price-module-${Math.floor(
      Math.random() * Date.now()
    ).toString(36)}`;
    this.containerTarget.setAttribute('id', this.containerId);
    const commodity = this.element.dataset['commodity'];
    window.dtn.futures.createFuturesTableWidget({
      apiKey: 'G2YnDkKGwJnB3F9jltRrJKyavGTWn6OE',
      container: `#${this.containerId}`,
      symbol: `${commodity}\`## 1`,
      visibleFields: ['LAST', 'CHANGE'],
      groupBy: true,
      priceFormat: 'DECIMAL',
      timezone: 'CST'
    });
  }
}
