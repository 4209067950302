import { Controller } from 'stimulus';

const allowedMessageOrigins = [
  /http:\/\/localhost.*/,
  /https?:\/\/.*\.cattlemarketcentral.com/
];

export default class StaticContentController extends Controller {
  initialize() {
    this.receiveMessage = this.receiveMessage.bind(this);
  }

  receiveMessage(event) {
    if (!allowedMessageOrigins.find(pattern => pattern.test(event.origin))) {
      return;
    }

    const element = this.element.querySelector('iframe');

    if (
      event.data.source == 'feeder-flash.embedded' &&
      (event.data.action == 'content-loaded' ||
        event.data.action == 'content-resized')
    ) {
      if (element && event.data.payload.clientHeight > 0) {
        element.classList.remove('h-100');

        element.style.height = `${event.data.payload.clientHeight}px`;
      }
    }
  }
}
