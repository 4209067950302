import { Controller } from 'stimulus';

export default class RealTimePrices extends Controller {
  static targets = [];

  sort(event) {
    const header = event.currentTarget;
    const table = header.closest('table');

    const getCellValue = (tr, idx) =>
      tr.children[idx].innerText || tr.children[idx].textContent;

    // TODO(Josh): Clean up
    const comparer = (idx, asc) => (a, b) =>
      ((v1, v2) =>
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2)
          ? v1 - v2
          : new Date(v1) == 'Invalid Date' || new Date(v2) == 'Invalid Date' // eslint-disable-next-line prettier/prettier
            ? v1.toString().localeCompare(v2) // eslint-disable-next-line prettier/prettier
            : new Date(v1) - new Date(v2))(
        getCellValue(asc ? a : b, idx),
        getCellValue(asc ? b : a, idx)
      );

    Array.from(table.querySelectorAll('tr:nth-child(n+2)'))
      .sort(
        comparer(
          Array.from(header.parentNode.children).indexOf(header),
          (this.asc = !this.asc)
        )
      )
      .forEach(tr => table.appendChild(tr));

    this.restripeTable(table);
  }

  restripeTable(table) {
    let visibleIndex = 0;

    table.querySelectorAll('tr').forEach(e => {
      const element = e;

      if (element.offsetWidth > 0 && element.offsetHeight > 0) {
        element.classList.remove('even', 'odd');
        const newClass = visibleIndex % 2 ? 'even' : 'odd';
        element.classList.add(newClass);
        visibleIndex += 1;
      }
    });
  }
}
