import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthenticatedCableContext from '../../../src/containers/AuthenticatedCableContext';
import { fromJS, Map } from 'immutable';

export default class PriceContainer extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired
  };

  constructor(...props) {
    super(...props);

    this.state = {
      pricePoint: Map()
    };

    this.handlePricePointReceived = this.handlePricePointReceived.bind(this);
  }

  handlePricePointReceived(payload) {
    this.setState(state => ({
      pricePoint: state.pricePoint.merge(fromJS(payload))
    }));
  }

  render() {
    const { name, render } = this.props;
    const { pricePoint } = this.state;

    return (
      <AuthenticatedCableContext.Provider>
        <AuthenticatedCableContext.Consumer
          channel={{
            channel: 'TV::PricePointChannel',
            name
          }}
          onReceived={this.handlePricePointReceived}
        >
          {render(pricePoint)}
        </AuthenticatedCableContext.Consumer>
      </AuthenticatedCableContext.Provider>
    );
  }
}
