/* global $ */

import { Controller } from 'stimulus';
import appClient from '../../src/lib/app-client';

export default class FollowController extends Controller {
  static targets = ['label'];

  toggle(e) {
    e.preventDefault();

    const isLoggedIn = this._verifyAuthentication();

    if(!isLoggedIn) {
      return this._showAuthenticationRequiredModal();
    }

    if (this.data.get('present') == 'true') {
      this._unfollow();
    } else {
      this._follow();
    }
  }

  // private

  _follow() {
    appClient.createChannelSubscription(this.data.get('channelId')).then(() => {
      this.data.set('present', true);
      this.labelTarget.textContent = 'Unfollow';
      this.element.classList.remove(this.data.get('inactiveClass'));
      this.element.classList.add(this.data.get('activeClass'));
    });
  }

  _showAuthenticationRequiredModal() {
    $('#authentication_required_modal').modal();
  }

  _unfollow() {
    appClient.deleteChannelSubscription(this.data.get('channelId')).then(() => {
      this.data.set('present', false);
      this.labelTarget.textContent = 'Follow';
      this.element.classList.remove(this.data.get('activeClass'));
      this.element.classList.add(this.data.get('inactiveClass'));
    });
  }

  _verifyAuthentication() {
    const userId = this.data.get('userId');

    return userId != null && userId != '';
  }
}
