import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Contract from '../Contract';

function CommodityTimelineModule({ name, contracts }) {
  return (
    <div className="commodity-timeline-module">
      <h1 className="commodity-timeline-module__title">{name}</h1>

      {contracts && contracts.length > 0 ? (
        contracts.map(contract => (
          <Contract key={btoa(JSON.stringify(contract))} {...contract} />
        ))
      ) : (
        <div className="align-items-center d-flex flex-column flex-grow-1 justify-content-center mb-5">
          <FontAwesomeIcon
            className="text-muted mb-4"
            fixedWidth
            icon={['far', 'chart-bar']}
            size="4x"
          />

          <small className="text-center text-muted text-uppercase">
            Contract data is currently unavailable.
          </small>
        </div>
      )}
    </div>
  );
}

CommodityTimelineModule.propTypes = {
  name: PropTypes.string.isRequired,
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      identifier: PropTypes.string,
      price: PropTypes.string,
      change: PropTypes.string
    })
  )
};

export default CommodityTimelineModule;
