import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ChannelSearchPopover from '../ChannelSearchPopover';

export default class DesktopSearch extends Component {
  static propTypes = {
    channels: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    channels: []
  };

  constructor(...props) {
    super(...props);

    this.domNode = React.createRef();
    this.inputNode = React.createRef();

    this.state = {
      showPopover: false,
      value: ''
    };

    this.togglePopover = this.togglePopover.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  togglePopover() {
    this.setState({
      showPopover: !this.state.showPopover
    });
  }

  handleChange() {
    this.setState(state => {
      return {
        ...state,
        value: this.inputNode.current.value,
        showPopover: this.inputNode.current.value.length > 0
      };
    });
  }

  filteredChannels(searchTerm) {
    const channels = this.props.channels.filter(
      channel =>
        channel.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(channel.number)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );

    return Array.from(new Set(channels.map(c => c.id))).map(id =>
      channels.find(c => c.id === id)
    );
  }

  handleSubmit(e, channels) {
    e.preventDefault();

    this.setState({
      showPopover: false
    });

    if (channels.length === 1) {
      // this.props.history.push(`/channels/${channels[0].slug}`);
    } else {
      // this.props.history.push(`/search/${this.state.value}`);
    }
  }

  render() {
    const { value } = this.state;
    const filteredChannels = this.filteredChannels(value);
    const buttonClasses = this.state.showPopover
      ? 'btn btn-secondary'
      : 'btn btn-light';

    return (
      <div ref={node => (this.node = node)}>
        <form
          className="form-inline mx-auto"
          ref={this.domNode}
          onSubmit={e => this.handleSubmit(e, filteredChannels)}
        >
          <div className="input-group input-group-sm">
            <input
              ref={this.inputNode}
              type="search"
              className="form-control"
              placeholder="Search channels..."
              value={value}
              onChange={this.handleChange}
            />

            <div className="input-group-append">
              <button className={buttonClasses}>
                <FontAwesomeIcon icon={['far', 'search']} />
              </button>
            </div>
          </div>
        </form>

        {this.state.showPopover
          ? ReactDOM.createPortal(
            <ChannelSearchPopover
              filteredChannels={filteredChannels}
              parentRef={this.domNode}
              searchTerm={value}
              togglePopover={this.togglePopover}
            />,
            document.body
          )
          : null}
      </div>
    );
  }
}
