import { Controller } from 'stimulus';

// Convert from underscore syntax to camelCase
const convertToCamelCase = str => {
  return str.replace(/(_\w)/g, function(m) {
    return m[1].toUpperCase();
  });
};

export default class WeightboardController extends Controller {
  static targets = [
    'selling',
    'consignor',
    'headCount',
    'averageWeight',
    'totalWeight',
    'price',
    'soldBy'
  ];

  connect() {
    setTimeout(() => this.updateDisplay(), 2000);
  }

  get xml() {
    return new Promise((resolve, reject) => {
      const xmlUrl = this.data.get('xmlUrl');
      let xmlHttp = new XMLHttpRequest();
      xmlHttp.open('GET', xmlUrl, true);
      xmlHttp.overrideMimeType('application/xml');

      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState === 4) {
          if (xmlHttp.status === 200) {
            resolve(xmlHttp.responseXML);
          } else {
            reject();
          }
        }
      };

      xmlHttp.error = () => {
        reject();
      };

      xmlHttp.send();
    });
  }

  async updateDisplay() {
    let xml = await this.xml.catch(() => (xml = null));

    if (xml !== null) {
      const lotNode = xml.childNodes[0]
      const data = lotNode.children;
      for (let i = 0; i < data.length; i++) {
        const dataPoint = data[i];
        const currentTarget = this[
          `${convertToCamelCase(dataPoint.tagName)}Target`
        ];
        currentTarget.value = dataPoint.textContent;
      }
      this.updateAverageWeightDisplay();
      this.toggleSoldByDisplay();
      this.toggleConsignorDisplay();
    }
    setTimeout(() => this.updateDisplay(), 2000);
  }

  toggleSoldByDisplay() {
    const soldBy = this.soldByTarget.value;

    if (soldBy !== undefined && soldBy !== '') {
      this.soldByTarget.parentElement.classList.remove('d-none');
    } else {
      this.soldByTarget.parentElement.classList.add('d-none');
    }
  }

  toggleConsignorDisplay() {
    const consignor = this.consignorTarget.value;

    if (consignor !== undefined && consignor !== '') {
      this.consignorTarget.parentElement.classList.remove('d-none');
    } else {
      this.consignorTarget.parentElement.classList.add('d-none');
    }
  }

  updateAverageWeightDisplay() {
    const headCount = parseFloat(this.headCountTarget.value);
    const totalWeight = parseFloat(this.totalWeightTarget.value);

    if (
      !isNaN(headCount) &&
      headCount > 0 &&
      !isNaN(totalWeight) &&
      totalWeight > 0
    ) {
      this.averageWeightTarget.value = Math.round(totalWeight / headCount);
    } else {
      this.averageWeightTarget.value = '';
    }
  }
}
