import { Controller } from 'stimulus';
import isVisible from '../../src/lib/utils/isVisible';
import scrollPosition from '../../src/lib/utils/scrollPosition';

const RESETTING_CLASS = 'vertical-carousel__items--resetting';

export default class VerticalCarouselController extends Controller {
  static targets = ['item', 'items', 'viewport'];

  allowedVarianceInRems = 0.25;

  initialize() {
    this.interval = parseInt(this.data.get('interval'));

    this.scroll = this.scroll.bind(this);
  }

  connect() {
    this.timerHandle = setInterval(this.scroll, this.interval);
  }

  disconnect() {
    clearInterval(this.timerHandle);
  }

  scroll() {
    const allVisibleItems = this._findVisible();
    const firstVisibleItem = allVisibleItems[0];

    const lastVisibleItem =
      allVisibleItems[Math.max(allVisibleItems.length - 1)];

    const nextSibling = this._findSibling(firstVisibleItem);

    if (nextSibling == null) return;

    const hiddenSibling = this._findSibling(lastVisibleItem);

    if (hiddenSibling == null) {
      const firstVisibleItemIndex = this.itemTargets.indexOf(firstVisibleItem);

      const allPreviousSiblings = Array.from(this.itemTargets).slice(
        0,
        firstVisibleItemIndex
      );

      this._enableReset()
        .then(() => {
          return this._moveItems(
            allPreviousSiblings,
            lastVisibleItem.parentNode
          );
        })
        .then(() => {
          return this._performScroll(
            scrollPosition(nextSibling, this.itemsTarget, 'y')
          );
        });
    } else {
      this._performScroll(scrollPosition(nextSibling, this.itemsTarget, 'y'));
    }
  }

  // private
  _enableReset() {
    return new Promise(resolve => {
      this.itemsTarget.classList.add(RESETTING_CLASS);

      const callback = () => resolve();

      requestAnimationFrame(callback.bind(this));
    });
  }

  _findSibling(visibleElement) {
    const allItems = Array.from(this.itemTargets);
    const visibleGroupIndex = allItems.indexOf(visibleElement);

    return allItems[visibleGroupIndex + 1] || null;
  }

  _findVisible() {
    return (
      Array.from(this.itemTargets).filter(item =>
        isVisible(
          item,
          this.viewportTarget,
          this.allowedVarianceInRems,
          'vertical'
        )
      ) || null
    );
  }

  _moveItems(items, parent) {
    return new Promise(resolve => {
      items.forEach(sibling => {
        parent.insertBefore(sibling, null);
      });

      this.itemsTarget.style.top = '0';

      resolve();
    });
  }

  _performScroll(top) {
    const callback = () => {
      this.itemsTarget.style.top = `-${top}rem`;
    };

    this.itemsTarget.classList.remove(RESETTING_CLASS);

    requestAnimationFrame(callback.bind(this));
  }
}
