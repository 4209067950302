import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Map } from 'immutable';

import ChangeDirection from './ChangeDirection';

function Price({ amount, change, name }) {
  return (
    <div className="price-module__price">
      <div className="price-module__price-name">{name}</div>
      <div className="price-module__price-amount">
        <span>
          {numeral(amount).format('$0,0.00')}

          <ChangeDirection
            className="price-module__change-direction"
            direction={change.get('direction')}
          />
        </span>

        <span className="price-module__change-amount">
          {numeral(change.get('amount')).format('0,0.00')}
        </span>
      </div>
    </div>
  );
}

Price.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  change: PropTypes.instanceOf(Map),
  name: PropTypes.string
};

export default Price;
