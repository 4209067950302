import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ShareIcon({ id, content, slug }) {
  return (
    <div
      className={'news-item__share-icon'}
      data-toggle="modal"
      data-target="#share-modal"
      data-action="click->share#updateLinks"
      data-news-item-id={id}
      data-news-item-content={content.substring(0, 279)}
      data-news-item-slug={slug}
    >
      <FontAwesomeIcon icon="share-alt" size="lg" />
    </div>
  );
}

ShareIcon.propTypes = {
  id: PropTypes.any,
  content: PropTypes.any,
  slug: PropTypes.any
};
