import React from 'react';
import PropTypes from 'prop-types';

function EmbedModule({ content }) {
  return (
    <div className="embed-module" dangerouslySetInnerHTML={{__html: content}} />
  );
}

EmbedModule.propTypes = {
  content: PropTypes.string.isRequired
};

export default EmbedModule;
