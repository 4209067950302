import { Controller } from 'stimulus';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import parse from 'date-fns/parse';

export default class FuzzyTimestampController extends Controller {
  initialize() {
    this.update = this.update.bind(this);
  }

  connect() {
    if (this.data.get('autoUpdate')) {
      this.timerHandle = setInterval(this.update, 60000);
    }
  }

  update() {
    const existingTimestamp = parse(this.data.get('time'));

    if (existingTimestamp == null) return;

    this.element.innerText = `${distanceInWordsToNow(existingTimestamp, {
      includeSeconds: true
    })} ago`;
  }
}
