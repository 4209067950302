import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HotIcon() {
  return (
    <div className={'news-item__hot-icon'}>
      <FontAwesomeIcon className="text-danger" icon="fire" size="lg" />
    </div>
  );
}
