/**
 * Pluralize a noun based on how many of that noun there are.
 *
 * @param {string} noun A noun (word).
 * @param {number} count The count of nouns.
 * @param {boolean} [includeCount=false] Whether to include the count before the
 * noun in the returned result.
 * @returns {string} The pluralized noun, optionally with the count prepended.
 */
function pluralize(noun, count, includeCount = false) {
  const nounBeforePluralize = `${includeCount ? count + ' ' : ''}${noun}`;

  if(count === 1) {
    return nounBeforePluralize;
  }

  return `${nounBeforePluralize}s`;
}

export default pluralize;
