/* global jQuery, ReactRailsUJS */

import { Controller } from 'stimulus';
import appClient from '../../src/lib/app-client';
import { normalize } from '../models/news_item';

export default class FocusModalController extends Controller {
  static targets = ['body', 'newsItem'];

  connect() {
    if (!this._hasAnchor()) return;

    if (this.hasNewsItemTarget) {
      ReactRailsUJS.unmountComponents(this.newsItemTarget);
      this.newsItemTarget.parentNode.removeChild(this.newsItemTarget);
    }

    this._getNewsItem(this._getAnchor(), newsItem => {
      this.bodyTarget.appendChild(
        this._buildComponentNode('NewsItem', newsItem)
      );

      ReactRailsUJS.mountComponents(this.bodyTarget);

      jQuery(this.element).modal();
    });
  }

  // private

  _buildComponentNode(componentName, componentProps) {
    const node = document.createElement('div');

    node.setAttribute('data-react-cache-id', `components/${componentName}-0`)
    node.setAttribute('data-react-class', `components/${componentName}`);
    node.setAttribute('data-react-props', JSON.stringify(componentProps));
    node.setAttribute('data-target', 'focus-modal.newsItem');

    return node;
  }

  _getAnchor() {
    const params = new URL(location).searchParams;
    let anchor = document.URL.split('#')[1] || params.get('news_item_id');

    return anchor != null && anchor.toString().trim() != ''
      ? anchor
      : undefined;
  }

  _getNewsItem(newsItemId, callback) {
    appClient.getNewsItem(newsItemId).then(payload => {
      callback.call(this, normalize(payload));
    });
  }

  _hasAnchor() {
    const anchor = this._getAnchor();

    return anchor !== undefined;
  }
}
