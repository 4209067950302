import { Controller } from 'stimulus';

export default class MarketReports extends Controller {
  static targets = ['title'];

  connect() {
    const defaultId = this.element.getAttribute('data-default-id');
    let defaultElement = null;

    if (typeof defaultId !== 'undefined' && defaultId != null && defaultId !== '') {
      defaultElement = document.querySelector(
        `.market-reports-page__body .selector-list .scrollable.nav [data-id="${defaultId}"]`
      );
    }

    if (defaultElement == null) {
      defaultElement = document.querySelector('.selector-list .scrollable a');
    }

    defaultElement.click();
    defaultElement.scrollIntoView();
    this.select({ currentTarget: defaultElement });
  }

  select(e) {
    this.titleTarget.innerHTML = e.currentTarget.innerHTML;
    const containerId = e.currentTarget.getAttribute('href').replace('#', '');
    const targetContainer = document.getElementById(containerId);

    if (targetContainer.innerHTML == '') {
      const id = e.currentTarget.getAttribute('data-id');
      const url = encodeURI(`/markets/${id}/last_market_report`);

      const request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.onload = function loadTabData() {
        if (this.status >= 200 && this.status < 400) {
          targetContainer.innerHTML = this.response;
        }
      };

      request.send();
    }
  }
}
