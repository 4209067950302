/* eslint-env node, browser */

import AppContext from './context';

function createChannelSubscription(channelId) {
  const context = new AppContext({ environment: process.env.NODE_ENV });

  return fetch(context.endpoint('channelSubscriptions.collection'), {
    body: JSON.stringify({
      'channel_subscription': {
        'channel_id': channelId
      }
    }),
    headers: {
      Accept: 'application/vnd.api+json;charset=UTF-8',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'POST'
  }).then(response => {
    if (response.ok) {
      return response.json();
    }

    const error = new Error('Unable to communicate with server!');
    error.name = 'CommunicationError';

    throw error;
  });
}

export default createChannelSubscription;
