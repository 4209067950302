import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthenticatedCable from 'lib/authenticated-cable';

const { Provider, Consumer } = React.createContext();
const cableUrl = document.querySelector('meta[name="action-cable-url"]')
  .content;

class AuthenticatedCableProvider extends Component {
  static propTypes = {
    children: PropTypes.any
  };

  constructor(...props) {
    super(...props);

    this.cable = AuthenticatedCable.createConsumer(cableUrl);
  }

  componentWillUnmount() {
    this.cable.disconnect();
  }

  render() {
    const { children } = this.props;

    return (
      <Provider value={{ cable: this.cable }}>{children || null}</Provider>
    );
  }
}

const AuthenticatedCableConsumer = React.forwardRef((props, ref) => (
  <Consumer>
    {({ cable }) => {
      const { children, ...propsForKey } = props;

      return (
        <AuthenticatedCableContainer
          cable={cable}
          key={JSON.stringify(propsForKey)}
          ref={ref}
          {...props}
        >
          {children}
        </AuthenticatedCableContainer>
      );
    }}
  </Consumer>
));

AuthenticatedCableConsumer.displayName = 'AuthenticatedCableConsumer';
AuthenticatedCableConsumer.propTypes = {
  children: PropTypes.any
};

class AuthenticatedCableContainer extends Component {
  static propTypes = {
    cable: PropTypes.object.isRequired,
    channel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onReceived: PropTypes.func,
    onInitialized: PropTypes.func,
    onConnected: PropTypes.func,
    onDisconnected: PropTypes.func,
    onRejected: PropTypes.func,
    children: PropTypes.any
  };

  subscription = null;

  componentDidMount() {
    const {
      cable,
      channel,
      onReceived,
      onInitialized,
      onConnected,
      onDisconnected,
      onRejected
    } = this.props;

    this.subscription = cable.subscriptions.create(channel, {
      received: data => onReceived && onReceived(data),
      initialized: data => onInitialized && onInitialized(data),
      connected: data => onConnected && onConnected(data),
      disconnected: data => onDisconnected && onDisconnected(data),
      rejected: data => onRejected && onRejected(data)
    });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.props.cable.subscriptions.remove(this.subscription);
      this.subscription = null;
    }
  }

  send(data) {
    if (!this.subscription) {
      throw new Error('ActionCable component is not connected.');
    }

    this.subscription.send(data);
  }

  perform(data) {
    if (!this.subscription) {
      throw new Error('ActionCable component is not connected.');
    }

    this.subscription.perform(data);
  }

  render() {
    return this.props.children;
  }
}

const AuthenticatedCableContext = {
  Provider: AuthenticatedCableProvider,
  Consumer: AuthenticatedCableConsumer
};

export default AuthenticatedCableContext;
