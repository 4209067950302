import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

function ChannelSearchItem({ name, number, slug, className }) {
  const classNames = ['d-block', styles.item];
  if (className) {
    classNames.push(className);
  }

  return (
    <a
      href={`/channels/${slug}`}
      className={classNames.join(' ')}
    >
      <p className="mb-0">{name}</p>
      <small className="text-muted">Channel {number}</small>
    </a>
  );
}

ChannelSearchItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  slug: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default ChannelSearchItem;
