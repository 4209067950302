import { Controller } from 'stimulus';

export default class Futures extends Controller {
  connect() {
    const commodity = this.element.dataset['futureSymbol'];

    window.dtn.futures.createFuturesTableWidget({
      apiKey: 'G2YnDkKGwJnB3F9jltRrJKyavGTWn6OE',
      container: this.element,
      symbol: `${commodity}\`## 5`,
      visibleFields: ['LAST', 'CHANGE'],
      groupBy: true,
      priceFormat: 'DECIMAL',
      timezone: 'CST'
    });

    setInterval(this.refresh, 600000, commodity, this.element);
  }

  refresh(commodity, element) {
    window.dtn.futures.createFuturesTableWidget({
      apiKey: 'G2YnDkKGwJnB3F9jltRrJKyavGTWn6OE',
      container: element,
      symbol: `${commodity}\`## 5`,
      visibleFields: ['LAST', 'CHANGE'],
      groupBy: true,
      priceFormat: 'DECIMAL',
      timezone: 'CST'
    });
  }
}
