import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';

function Contract({ change, identifier, name, price }) {
  const changeAsFloat = change => parseFloat(change);

  const direction = change =>
    !Number.isNaN(changeAsFloat(change)) && changeAsFloat(change) > 0.0
      ? 'up'
      : 'down';

  return (
    <div
      key={`timeline-${identifier}`}
      className="commodity-timeline-module__contract"
    >
      <div className="commodity-timeline-module__label">
        <span className="text-primary">{name}</span>

        <small>{identifier}</small>
      </div>

      <div className="commodity-timeline-module__amount">
        <span>
          {numeral(price).format('$0,0.00')}

          {direction ? (
            <FontAwesomeIcon
              icon={`arrow-alt-${direction(change)}`}
              style={{
                fontSize: '.75em',
                marginLeft: '.25rem',
                verticalAlign: 'baseline'
              }}
              fixedWidth
            />
          ) : null}
        </span>

        <small className="font-weight-normal text-muted">{change}</small>
      </div>
    </div>
  );
}

Contract.propTypes = {
  change: PropTypes.string,
  identifier: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string
};

export default Contract;
