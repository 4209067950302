import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SUBSCRIPTION_IDLE = 'SUBSCRIPTION_IDLE';
const SUBSCRIPTION_IN_FLIGHT = 'SUBSCRIPTION_IN_FLIGHT';
const SUBSCRIPTION_COMPLETE = 'SUBSCRIPTION_COMPLETE';
const SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR';

export default class NewsletterSubscription extends Component {
  static propTypes = {
    email: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: {
        email: null
      },
      form: {
        email: props.email || ''
      },
      status: SUBSCRIPTION_IDLE
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  subscribe({ email }) {
    const body = `email=${encodeURIComponent(email)}`;

    fetch('/api/internal/subscribers', {
      body,
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'POST',
      mode: 'same-origin'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Server returned ${response.status}`);
        }

        this.setState(state => ({ ...state, status: SUBSCRIPTION_COMPLETE }));
      })
      .catch(error =>
        this.setState(state => ({
          ...state,
          errors: { email: `Unable to subscribe: ${error.message}` },
          status: SUBSCRIPTION_ERROR
        }))
      );
  }

  handleChange(e) {
    const { value } = e.currentTarget;

    this.setState(state => ({ ...state, form: { email: value } }));
  }

  handleSubmit(e) {
    const {
      form: { email }
    } = this.state;

    if (email.trim() == '') {
      this.setState(state => ({
        ...state,
        errors: { email: 'Must provide a valid email address.' }
      }));
    } else {
      this.subscribe({ email });
    }

    e.preventDefault();
  }

  render() {
    const {
      errors: { email: error },
      form: { email },
      status
    } = this.state;

    return (
      <div className="newsletter-subscription">
        <div className="newsletter-subscription__header">
          <h2 className="newsletter-subscription__title">Subscribe!</h2>
        </div>

        <div className="newsletter-subscription__body">
          <p>
            The National Beef Wire newsletter is <strong>your</strong> source
            for cattle industry news and information.
          </p>

          {status === SUBSCRIPTION_COMPLETE ? (
            <div className="newsletter-subscription__success-notice">
              <FontAwesomeIcon
                className="newsletter-subscription__success-notice-icon"
                icon={['fad', 'check-circle']}
                size="6x"
              />

              <h6 className="newsletter-subscription__success-notice-text">
                You&apos;re subscribed!
              </h6>
            </div>
          ) : (
            <div className="newsletter-subscription__form">
              <p>
                Enter your email address to be subscribed to the National Beef
                Wire newsletter today!
              </p>

              <input
                className="newsletter-subscription__input"
                disabled={status == SUBSCRIPTION_IN_FLIGHT}
                onChange={this.handleChange}
                placeholder="your-email@address.com"
                type="text"
                value={email}
              />

              <button
                className="newsletter-subscription__button"
                disabled={status == SUBSCRIPTION_IN_FLIGHT}
                onClick={this.handleSubmit}
              >
                {status == SUBSCRIPTION_IN_FLIGHT ? (
                  <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                ) : (
                  'Subscribe'
                )}
              </button>

              {error && (
                <span className="newsletter-subscription__error-text">
                  {error}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
