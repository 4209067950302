import React from 'react';
import PropTypes from 'prop-types';

function TextModule({ content }) {
  return (
    <div className="text-module" dangerouslySetInnerHTML={{__html: content}} />
  );
}

TextModule.propTypes = {
  content: PropTypes.string.isRequired
};

export default TextModule;
