import React from 'react';
import PropTypes from 'prop-types';

function NewsItemChannel({ name, slug }) {
  return (
    <a className={'news-item__channel-link'} href={`/channels/${slug}`}>
      {name}
    </a>
  );
}

NewsItemChannel.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired
};

export default NewsItemChannel;
