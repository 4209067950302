import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NewsFeedLoading() {
  return (
    <div className="news-feed--loading">
      <FontAwesomeIcon icon={['fad', 'spinner-third']} size="3x" spin />

      <h4 className="news-feed__loading-title">Loading</h4>

      <p className="news-feed__loading-message">
        We&apos;re grabbing the latest news items for this feed. Hang on a
        sec...
      </p>
    </div>
  );
}
