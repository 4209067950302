import { Controller } from 'stimulus';
import pxToRem from '../../src/lib/utils/pxToRem';

export default class NewsTickerController extends Controller {
  static targets = ['newsItem', 'viewport'];

  initialize() {
    this.pauseAnimation = this.pauseAnimation.bind(this);
    this.resumeAnimation = this.resumeAnimation.bind(this);
  }

  connect() {
    const elementWidth = this._getWidthFor(this.element);

    const animationSteps = [
      { transform: `translateX(${pxToRem(elementWidth)}rem)` },
      { transform: 'translateX(-100%)' }
    ];

    const animationTiming = {
      duration: this.newsItemTargets.length * 12000,
      iterations: Infinity
    };

    this.animation = this.viewportTarget.animate(animationSteps, animationTiming);
  }

  pauseAnimation() {
    this.animation && this.animation.pause();
  }

  resumeAnimation() {
    this.animation && this.animation.play();
  }

  // private

  _getWidthFor(element) {
    return (
      parseInt(
        window
          .getComputedStyle(element)
          .getPropertyValue('width')
          .replace(/[^0-9.-]/g, '')
      ) || 0
    );
  }
}
