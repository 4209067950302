import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PriceLoading({ name }) {
  return (
    <div className="price-module__price">
      <div className="price-module__price-name">{name}</div>
      <div className="price-module__price-amount">
        <FontAwesomeIcon fixedWidth icon={['fad', 'spinner-third']} spin />
      </div>
    </div>
  );
}

PriceLoading.propTypes = {
  name: PropTypes.string
};

export default PriceLoading;
